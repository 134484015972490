import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export interface CheckboxCheckedProps extends SvgIconProps {
  disabled?: boolean;
}

const CheckboxChecked = (props: CheckboxCheckedProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" style={{ opacity: props.disabled ? 0.5 : 1 }}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-677.000000, -913.000000)" fillRule="nonzero">
          <g transform="translate(677.000000, 913.000000)">
            <path
              d="M24,2.75006105 C24,1.23303225 22.7669677,0 21.249939,0 L2.75006105,0 C1.23303225,0 0,1.23303225
            0,2.75006105 L0,21.249939 C0,22.7669678 1.23303225,24 2.75006105,24 L21.249939,24
            C22.7669678,24 24,22.7669677 24,21.249939 L24,2.75006105 Z"
              fill="#36C4EB"
            />
            <path
              d="M18.0820312,8.04309084 C18.4729614,8.43402098 18.4729614,9.06591797 18.0820312,9.45703125
            L11.5819702,15.9569092 C11.3869629,16.1519165 11.1309815,16.250061 10.875,16.250061
            C10.6190185,16.250061 10.3630371,16.1519165 10.1680298,15.9569092 L6.91809084,12.7069702
            C6.52697756,12.3160401 6.52697756,11.6839599 6.91809084,11.2930298 C7.30902098,10.9019165
            7.94091797,10.9019165 8.33203125,11.2930298 L10.875,13.8359985 L16.6680908,8.04309084
            C17.059021,7.65197756 17.690918,7.65197756 18.0820312,8.04309084 Z"
              fill="#FAFAFA"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CheckboxChecked;
