import { ChangeEvent } from "react";

import Checkbox from "components/shared/Checkbox";
import FlexContainer from "components/shared/FlexContainer";
import Typography from "components/shared/Typography";
import FeedbackCheck from "icons/checkbox/FeedbackCheck";
import FeedbackCross from "icons/checkbox/FeedbackCross";

import Textarea from "../Textarea";

import { useStyles } from "./FeedbackLoop.styles";

export enum FeedbackValues {
  POSITIVE = "positive",
  NEGATIVE = "negative",
}

export interface FeedbackLoopProps {
  labelFeedback?: string;
  onChangeFeedback?: (value: FeedbackValues) => void;
  feedbackValue?: FeedbackValues;
  labelTextarea?: string;
  onChangeTextarea?: (value: string) => void;
  textareaValue?: string | null;
  textareaPlaceholder?: string;
}

const FeedbackLoop = ({
  labelFeedback,
  labelTextarea,
  textareaValue,
  feedbackValue,
  onChangeFeedback,
  onChangeTextarea,
  textareaPlaceholder,
}: FeedbackLoopProps) => {
  const classes = useStyles();

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.checked) {
      return;
    }
    onChangeFeedback!(event.target.name as FeedbackValues);
  };

  const handleTextareaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeTextarea!(event.target.value);
  };

  return (
    <div className={classes.container}>
      {onChangeFeedback && (
        <FlexContainer center spaceBetween data-testid="feedback-loop">
          <Typography variant="subtitle1">{labelFeedback}</Typography>
          <div>
            <Checkbox
              classes={{
                root: classes.checkbox,
              }}
              name={FeedbackValues.POSITIVE}
              checked={feedbackValue === FeedbackValues.POSITIVE}
              onChange={handleOnChange}
              iconChecked={<FeedbackCheck checked={true} />}
              iconBlank={<FeedbackCheck checked={false} />}
              data-testid="feedback-loop-positive"
            />
            <Checkbox
              classes={{
                root: classes.checkbox,
              }}
              name={FeedbackValues.NEGATIVE}
              checked={feedbackValue === FeedbackValues.NEGATIVE}
              onChange={handleOnChange}
              iconChecked={<FeedbackCross checked={true} />}
              iconBlank={<FeedbackCross checked={false} />}
              data-testid="feedback-loop-negative"
            />
          </div>
        </FlexContainer>
      )}
      {onChangeTextarea && (
        <FlexContainer vertical gap="sm">
          {labelTextarea && <Typography variant="subtitle2">{labelTextarea}</Typography>}
          <Textarea
            value={textareaValue ?? undefined}
            onChange={handleTextareaChange}
            placeholder={textareaPlaceholder}
            fullWidth
            testId="feedback-loop-textarea"
            maxLength={4000}
          />
        </FlexContainer>
      )}
    </div>
  );
};

export default FeedbackLoop;
