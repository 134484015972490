export interface FeedbackCrossProps {
  checked: boolean;
}

const FeedbackCross = ({ checked }: FeedbackCrossProps) => {
  const color = checked ? "#36C4EB" : "#B6BAC6";
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="3.5"
        fill={checked ? color : "white"}
        fillOpacity={checked ? "0.1" : undefined}
      />
      <path
        d="M12 16.5333L14.2857 18.6666L20 13.3333"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke={color} />
    </svg>
  );
};

export default FeedbackCross;
