import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export interface CheckboxBlankProps extends SvgIconProps {
  disabled?: boolean;
}

const CheckboxBlank = (props: CheckboxBlankProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24 " style={{ opacity: props.disabled ? 0.5 : 1 }}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-677.000000, -913.000000)" fillRule="nonzero" stroke="#B6BAC6" strokeWidth="1.5">
          <g id="ic/16/checkbox/default" transform="translate(677.000000, 913.000000)">
            <path
              d="M21.8181679,0.75 C22.2128827,0.75 22.570695,0.910893806
            22.8299337,1.17013248 C23.0891059,1.42930474 23.25,1.78703755 23.25,2.18183213 L23.25,2.18183213
            L23.25,21.8181679 C23.25,22.2129624 23.0891059,22.5706953 22.8299337,22.8298675 C22.570695,23.0891062
            22.2128827,23.25 21.8181679,23.25 L21.8181679,23.25 L2.18183213,23.25 C1.78712206,23.25
            1.42931584,23.0890978 1.17007893,22.8298609 C0.910902409,22.5706844 0.75,22.2129504
            0.75,21.8181679 L0.75,21.8181679 L0.75,2.18183213 C0.75,1.78703755 0.910894086,1.42930474
            1.17006634,1.17013248 C1.42930502,0.910893806 1.7871173,0.75 2.18183213,0.75 L2.18183213,0.75 Z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default CheckboxBlank;
