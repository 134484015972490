import { Activity } from "./Activity";
import { VerificationStates } from "./VerificationStates";

export interface VerificationTypePeriod {
  id: string;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  verifierOrganisationId: string | null;
  dob?: string | null;
  nin?: string | null;
  newJobTitle?: string | null;
  newEmployerName?: string | null;
  history?: VerificationTypePeriodHistory[];
  employeeEmail: string | null;
  isTimelineAvailable: boolean;
  type: VerificationType.PERIOD;
  activities: Activity[];
  reviewed: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
  };
}

export enum CancelAction {
  CONFIRMED = "confirmed",
  RETRIED = "retried",
}

export enum CancelReasonType {
  REJECTED = "Rejected",
  TIMED_OUT = "Timed out",
  UNSUPPORTED_BANKING = "Unsupported - open banking",
  UNSUPPORTED_PAYROLL = "Unsupported - payroll",
  DUPLICATE = "Duplicate",
  FAILED = "Failed",
  VERIFIER_CANCELLED = "Verifier cancelled",
}

export interface VerificationTypePeriodHistory {
  state: VerificationStates;
  verificationId: string;
  reason: CancelReasonType | null;
  cancelAction: CancelAction | null;
  reasonCreatedAt: string;
  createdBy: {
    firstName: string;
    lastName: string;
    id: string;
  };
}

export enum VerificationTypePeriodView {
  SUBMIT = "submit",
  EMPLOYEE = "employee",
  COMPLETED = "completed",
}

export enum VerificationType {
  PERIOD = "period",
  INBOUND_REFERENCING = "inbound_referencing",
  SINGLE_EMPLOYER = "single_employer",
}

// type guard
export const isVerificationTypePeriod = (verification: any): verification is VerificationTypePeriod =>
  Array.isArray((verification as VerificationTypePeriod).activities);
