export interface Configuration {
  smsOrganisationName: string | null;
  openbankingDisplaySpending: boolean;
  openbankingDisplayIncomeAmount: boolean;
  incomeVerificationEnabled: boolean;
  hmrcEnabled: boolean;
  payrollEnabled: boolean;
  channelsEnabled: boolean;
  alertsEnabled: boolean;
}

export enum OrganisationRegisterSource {
  SIGN_UP = "signup",
  INBOUND_REFERENCING = "inbound_referencing",
}

export interface Organization {
  id: string;
  name: string;
  address: string;
  createdAt: number;
  employees: number;
  email: string;
  roles: string[];
  logoUrl: string | null;
  updatedAt: number;
  slug: string;
  registerSource: OrganisationRegisterSource;
  configuration: Configuration;
}
