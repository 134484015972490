import classnames from "classnames";
import { FC, HTMLAttributes } from "react";

import styles from "./FlexContainer.module.css";

export interface FlexContainerProps extends HTMLAttributes<HTMLDivElement> {
  spaceBetween?: boolean;
  verticalMargin?: boolean;
  vertical?: boolean;
  center?: boolean;
  gap?: "none" | "sm" | "md" | "lg" | "xl";
}

const FlexContainer: FC<FlexContainerProps> = (props) => {
  const classes = classnames(
    styles.root,
    props.className,
    { [styles.spaceBetween]: props.spaceBetween },
    { [styles.verticalMargin]: props.verticalMargin },
    { [styles.vertical]: props.vertical },
    { [styles.center]: props.center },
    { [styles.gapNone]: props.gap === "none" },
    { [styles.gapSm]: props.gap === "sm" },
    { [styles.gapMd]: props.gap === "md" },
    { [styles.gapLg]: props.gap === "lg" },
    { [styles.gapXl]: props.gap === "xl" },
  );
  return <div className={classes}>{props.children}</div>;
};

export default FlexContainer;
