import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  container: {
    background: "#F6F8F9",
    padding: "10px 16px",
    borderRadius: "4px",
  },
  checkbox: {
    padding: "6px !important",
  },
}));
