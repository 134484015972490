import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { VerifierEndpointNames } from "@/api/query/base";
import {
  timelineApi,
  useGetTimelineQuery,
  useReviewFraudInsightsMutation,
  useUpdateTimelineReviewStatusMutation,
} from "@/api/query/verifier-timeline";
import { FeedbackValues } from "@/components/shared/FeedbackLoop";
import useActiveOrganisation from "@/hooks/useActiveOrganisation";
import useBreadcrumb from "@/hooks/useBreadcrumb";
import {
  ConnectionStatus,
  TimelineTransformed,
  TransactionItem,
  VerificationStatus,
  VerifierReview,
} from "@/hooks/useTimeline/types";
import { RootState, useAppDispatch } from "@/store";
import { setSnackbar } from "@/store/snackbar/actions";
import { MessageType } from "@/store/snackbar/types";
import {
  cancelConfirmVerificationRequestAction,
  cancelRetryVerificationRequestAction,
  closeSuccessModal,
  getVerificationAction,
  reviewTransactionsAction,
  updateFeedbackLoopAction,
  updateVerificationReviewedStatusAction,
} from "@/store/verificationV2/verification-v2-slice";
import { ActivityType } from "@/types/Activity";
import { CancelReasonType } from "@/types/Verification";
import { VerificationStates } from "@/types/VerificationStates";
import Analytics, { EVENT_PROPERTIES, VerifierEventName } from "@/utils/analytics";
import { verifierPageUrls } from "@/utils/routing";

export interface UseReviewVerification {
  verification: TimelineTransformed | undefined;
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  closeModal: () => void;
  handlePushVerificationsList: () => void;
  feedbackLoopTextareaChange: (
    verificationId: string,
    activityId: string,
    isVerified: boolean | null,
    notes: string | null,
  ) => void;
  feedbackLoopButtonChange: (
    verificationId: string,
    activityId: string,
    value: FeedbackValues,
    notes: string | null,
  ) => void;
  updateVerificationReviewedStatus: (verificationId: string) => void;
  getVerificationRequest: (id: string, orgId: string) => void;
  showFinishReviewingModal: boolean | null;
  verificationState?: VerificationStates;
  shouldDisplayActions: boolean;
  cancelReason?: CancelReasonType | null;
  showCancelledAlert: boolean;
  cancelRetryVerificationRequest: (verificationId: string, orgId: string) => void;
  cancelConfirmVerificationRequest: (verificationId: string, orgId: string) => void;
  handleRetryClick: (verificationId: string, orgId: string) => void;
  handleConfirmClick: (verificationId: string, orgId: string) => void;
  reviewTransactions: (
    orgId: string,
    verificationId: string,
    timelineId: string,
    activityId: string,
    transactions: TransactionItem[],
    verifierReview: VerifierReview,
    callback: () => void,
    type: ActivityType,
  ) => void;
  refetchTimeline: () => void;
  isUpdateTimelineReviewStatusLoading: boolean;
  updateTimelineReviewStatus: (verificationId: string, organisationId: string) => void;
  shouldShowFinishReviewingModal: boolean;
  reviewFraudInsightsMutation: (
    organisationId: string,
    verificationId: string,
    timelineId: string,
    activityId: string,
    verifierReviewedAsUseful: boolean,
  ) => void;
  isReviewFraudInsightsLoading: boolean;
}

export const useReviewVerification = (): UseReviewVerification => {
  const dispatch = useAppDispatch();
  const breadcrumb = useBreadcrumb();
  const router = useRouter();
  const { organisationId, verificationId } = router.query;
  const { name } = useActiveOrganisation();

  const isPathVerifications = router.pathname.includes("verifications");

  const {
    data: verification,
    error,
    isLoading,
    refetch: refetchTimeline,
  } = useGetTimelineQuery(
    {
      organisationId: organisationId as string,
      verificationId: verificationId as string,
    },
    {
      skip: !router.isReady || !isPathVerifications,
      refetchOnMountOrArgChange: true,
    },
  );

  const shouldShowFinishReviewingModal = verification?.activities?.some(
    (activity) =>
      activity.connectionStatus === ConnectionStatus.CONNECTED &&
      activity.verificationStatus === VerificationStatus.NOT_VERIFIED &&
      activity.income.length > 0 &&
      activity.verifierReview === null,
  );

  const [updateTimelineReviewStatusMutationTrigger, { isLoading: isUpdateTimelineReviewStatusLoading }] =
    useUpdateTimelineReviewStatusMutation();

  const [reviewFraudInsightsMutatuonTrigger, { isLoading: isReviewFraudInsightsLoading }] =
    useReviewFraudInsightsMutation();

  const verificationState = verification?.history && verification?.history[verification?.history.length - 1].state;
  const showCancelledAlert = verificationState === VerificationStates.CANCELLED;
  const cancelledHistoryItem =
    verification?.history &&
    [...verification?.history].reverse().find((history) => history.state === VerificationStates.CANCELLED);
  const cancelReason = cancelledHistoryItem?.reason;
  const allowRetryReasons = [CancelReasonType.TIMED_OUT, CancelReasonType.FAILED, CancelReasonType.VERIFIER_CANCELLED];
  const shouldDisplayActions =
    allowRetryReasons.includes(cancelReason as CancelReasonType) && !cancelledHistoryItem?.cancelAction;

  const showFinishReviewingModal = useSelector<RootState, boolean | null>(
    (state) => state.verification.showFinishReviewingModal,
  );

  const closeModal = useCallback(() => {
    dispatch(closeSuccessModal());
  }, [dispatch]);

  const handlePushVerificationsList = useCallback(() => {
    closeModal();
    if (router.isReady && organisationId) {
      breadcrumb.restore("requests-list", verifierPageUrls.requests(organisationId as string));
    }
  }, [router.isReady, organisationId, closeModal, breadcrumb]);

  const getVerificationRequest = useCallback(
    (id: string, orgId: string) => {
      dispatch(getVerificationAction({ id, orgId }));
    },
    [dispatch],
  );

  const updateVerificationReviewedStatus = useCallback(
    (verificationId: string) => {
      dispatch(updateVerificationReviewedStatusAction({ verificationId }));
    },
    [dispatch],
  );

  const feedbackLoopTextareaChange = useCallback(
    (verificationId: string, activityId: string, isVerified: boolean | null, notes: string | null) => {
      const feedbackLoop = {
        isVerified,
        notes,
      };
      Analytics.sendEvent(VerifierEventName.UPDATE_FEEDBACK_LOOP_NOTES, {
        verificationId,
        activityId,
        value: String(feedbackLoop.notes),
        length: String((<string>feedbackLoop.notes).length),
      });
      dispatch(updateFeedbackLoopAction({ verificationId, activityId, feedbackLoop }));
    },
    [dispatch],
  );

  const feedbackLoopButtonChange = useCallback(
    (verificationId: string, activityId: string, value: FeedbackValues, notes: string | null) => {
      const feedbackLoop = {
        isVerified: value === FeedbackValues.POSITIVE,
        notes,
      };
      Analytics.sendEvent(VerifierEventName.CLICK_FEEDBACK_LOOP_IS_VERIFIED_BUTTON, {
        verificationId,
        activityId,
        value: String(feedbackLoop.isVerified),
      });
      dispatch(updateFeedbackLoopAction({ verificationId, activityId, feedbackLoop }));
    },
    [dispatch],
  );

  const cancelRetryVerificationRequest = useCallback(
    (verificationId: string, orgId: string) => {
      dispatch(cancelRetryVerificationRequestAction({ verificationId, orgId }));
    },
    [dispatch],
  );

  const cancelConfirmVerificationRequest = useCallback(
    (verificationId: string, orgId: string) => {
      dispatch(cancelConfirmVerificationRequestAction({ verificationId, orgId }));
    },
    [dispatch],
  );

  const handleRetryClick = async (verificationId: string, orgId: string) => {
    const analyticsProps = {
      [EVENT_PROPERTIES.VERIFIER_ORGANISATION_NAME]: name!,
      [EVENT_PROPERTIES.VERIFICATION_ID]: verificationId,
    };
    Analytics.sendEvent(VerifierEventName.CLICK_RETRY_CANCELLATION, analyticsProps);
    cancelRetryVerificationRequest(verificationId, orgId);
  };

  const handleConfirmClick = async (verificationId: string, orgId: string) => {
    const analyticsProps = {
      [EVENT_PROPERTIES.VERIFIER_ORGANISATION_NAME]: name!,
      [EVENT_PROPERTIES.VERIFICATION_ID]: verificationId,
    };
    Analytics.sendEvent(VerifierEventName.CLICK_CONFIRM_CANCELLATION, analyticsProps);
    cancelConfirmVerificationRequest(verificationId, orgId);
  };

  const reviewTransactions = useCallback(
    (
      orgId: string,
      verificationId: string,
      timelineId: string,
      activityId: string,
      transactions: TransactionItem[],
      verifierReview: VerifierReview,
      callback: () => void,
      type: ActivityType,
    ) => {
      dispatch(
        reviewTransactionsAction({
          orgId,
          verificationId,
          timelineId,
          activityId,
          transactions,
          verifierReview,
          callback,
          type,
        }),
      );
    },
    [dispatch],
  );

  const updateTimelineReviewStatus = useCallback(
    async (verificationId: string, organisationId: string) => {
      await updateTimelineReviewStatusMutationTrigger({ verificationId })
        .unwrap()
        .then((response) => {
          dispatch(
            timelineApi.util.updateQueryData(
              VerifierEndpointNames.GET_TIMELINE,
              { verificationId, organisationId },
              (draft) => {
                draft.reviewed = response;
              },
            ),
          );
          dispatch(setSnackbar(true, MessageType.SUCCESS, "Review status changed successfully"));
        })
        .catch(() => {
          dispatch(setSnackbar(true, MessageType.ERROR, "Something went wrong"));
        });
    },
    [dispatch, updateTimelineReviewStatusMutationTrigger],
  );

  const reviewFraudInsightsMutation = useCallback(
    (
      organisationId: string,
      verificationId: string,
      timelineId: string,
      activityId: string,
      verifierReviewedAsUseful: boolean,
    ) => {
      reviewFraudInsightsMutatuonTrigger({
        organisationId,
        verificationId,
        timelineId,
        activityId,
        verifierReviewedAsUseful,
      })
        .unwrap()
        .then(() => {
          dispatch(
            timelineApi.util.updateQueryData(
              VerifierEndpointNames.GET_TIMELINE,
              { verificationId, organisationId },
              (draft) => {
                draft.activities = draft.activities.map((activity) => {
                  if (activity.id === activityId) {
                    return {
                      ...activity,
                      fraud: {
                        ...activity.fraud!,
                        fraudulentEmployer: {
                          ...activity.fraud!.fraudulentEmployer,
                          verifierReviewedAsUseful,
                        },
                      },
                    };
                  }
                  return activity;
                });
              },
            ),
          );
        });
    },
    [dispatch, reviewFraudInsightsMutatuonTrigger],
  );

  return {
    verification,
    isLoading,
    error,
    closeModal,
    handlePushVerificationsList,
    getVerificationRequest,
    feedbackLoopTextareaChange,
    feedbackLoopButtonChange,
    updateVerificationReviewedStatus,
    showFinishReviewingModal,
    handleRetryClick,
    handleConfirmClick,
    showCancelledAlert,
    verificationState,
    cancelReason,
    shouldDisplayActions,
    cancelRetryVerificationRequest,
    cancelConfirmVerificationRequest,
    reviewTransactions,
    refetchTimeline,
    shouldShowFinishReviewingModal: shouldShowFinishReviewingModal || false,
    isUpdateTimelineReviewStatusLoading,
    updateTimelineReviewStatus,
    reviewFraudInsightsMutation,
    isReviewFraudInsightsLoading,
  };
};
