import classnames from "classnames";
import React, { ChangeEvent } from "react";

import Typography from "../Typography";

import styles from "./textarea.module.css";

export interface TextareaProps {
  label?: string;
  value?: string | null;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  fullWidth?: boolean;
  resize?: boolean;
  testId?: string;
  id?: string;
  name?: string;
  maxLength?: number;
  md?: boolean;
}

const Textarea = ({
  label,
  value,
  onChange,
  placeholder,
  fullWidth,
  resize,
  name,
  id,
  testId,
  maxLength,
  md,
}: TextareaProps) => {
  const classes = classnames(
    styles.textarea,
    { [styles.fullWidth]: fullWidth },
    { [styles.noResize]: !resize },
    { [styles.md]: md },
  );
  return (
    <div className={fullWidth ? styles.fullWidth : ""}>
      {label && (
        <Typography className={styles.label} gutterBottom>
          {label}
          <span className={styles.optionalSpan}>&nbsp;(Optional)</span>
        </Typography>
      )}
      <textarea
        id={id}
        name={name}
        className={classes}
        value={value ?? undefined}
        onChange={onChange}
        placeholder={placeholder}
        data-testid={testId}
        maxLength={maxLength}
      />
    </div>
  );
};

export default Textarea;
