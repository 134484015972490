import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

import {
  Insight,
  InsightTransformed,
  TimelineActivityInsightEmployerDetails,
  TimelineActivityInsightTradingDateMismatch,
  TimelineAlert,
} from "@/components/Timeline/helpers/insightsAndAlertsUtils";
import { ReferenceType } from "@/forms/ApplicantDetails/ApplicantDetails";
import { ActivityType } from "@/types/Activity";
import { VerificationType, VerificationTypePeriodHistory } from "@/types/Verification";
import { VerificationStates } from "@/types/VerificationStates";

export interface TimelineSource {
  providerId: string;
  providerName: string;
}

export enum VerificationStatus {
  VERIFIED = "verified",
  NOT_VERIFIED = "not_verified",
}

export enum ConnectionStatus {
  CONNECTED = "connected",
  NOT_CONNECTED = "not_connected",
}

export type DeclaredData = {
  startDate: string;
  endDate: string;
  jobTitle: string;
  fullname: string;
  nationalInsuranceNumber: string;
  employerName: string;
};

export type TransactionItem = {
  counterparty: string;
  firstPaymentDate: string;
  lastPaymentDate: string;
  count: number;
  sum?: number;
  median?: number;
  verified?: boolean;
};

export enum VerifierReview {
  VERIFIED = "verified",
  UNABLE_TO_VERIFY = "unable_to_verify",
}

export enum PayFrequency {
  WEEKLY = "weekly",
  FORTNIGHTLY = "fortnightly",
  FOUR_WEEKLY = "four_weekly",
  MONTHLY = "monthly",
  TWO_MONTHLY = "two_monthly",
  QUARTERLY = "quarterly",
  HALF_YEARLY = "half_yearly",
  YEARLY = "yearly",
  OTHER = "other",
  NONE = "none",
}

export type IncomeInfo = {
  payCurrency: string;
  payFrequency: PayFrequency;
  latestPayDate: string;
  basePay: string;
  grossIncomeRolling: string;
  grossMonthlyIncome: string;
  netIncomeRolling: string;
  grossIncomeYtd: string;
  netIncomeYtd: string;
  grossIncomeTaxYtd: string;
  netIncomeTaxYtd: string;
};

export interface TimelineActivity {
  id: string;
  createdAt: string;
  updatedAt: string;
  type: ActivityType;
  sources: TimelineSource[];
  startDate: string | null;
  endDate: string | null;
  firstPaymentDate: string | null;
  lastPaymentDate: string | null;
  fullname: string;
  jobTitle: string;
  incomeInfo?: IncomeInfo | null;
  insights?: Insight[] | null;
  alerts?: TimelineAlert[] | null;
  nationalInsuranceNumber: string;
  employerName: string;
  declared: boolean;
  declaredData: DeclaredData;
  verificationStatus: VerificationStatus;
  connectionStatus: ConnectionStatus;
  income: TransactionItem[];
  verifierReview: VerifierReview | null;
}

export interface Timeline {
  id: string;
  timelineId: string;
  verificationId: string;
  createdAt: string;
  updatedAt: string;
  dob: string;
  employeeEmail: string | null;
  firstName: string;
  history?: VerificationTypePeriodHistory[];
  isSandbox: boolean;
  lastName: string;
  middleName: string | null;
  newEmployerName: string | null;
  newJobTitle: string | null;
  referenceType: ReferenceType;
  nin: string | null;
  phoneNumber: string | null;
  state: VerificationStates;
  type: VerificationType;
  verifierOrganisationId: string | null;
  activities: TimelineActivity[];
  reviewed: {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    createdAt: string;
  };
}

export interface TimelineActivityTransformed extends Omit<TimelineActivity, "insights"> {
  insights: InsightTransformed[] | null;
  fraud: TimelineActivityInsightEmployerDetails | null;
  tradingDatesMismatch: TimelineActivityInsightTradingDateMismatch | null;
}

export interface TimelineTransformed extends Omit<Timeline, "activities"> {
  activities: TimelineActivityTransformed[];
}

export interface UseTimeline {
  verification: TimelineTransformed | undefined;
  error: FetchBaseQueryError | SerializedError | undefined;
  isLoading: boolean;
  handleTimelineTabClick: () => void;
  handleActivityTabClick: () => void;
  handleBack: () => void;
  handleRetryClick: (verificationId: string, orgId: string) => void;
  handleConfirmClick: (verificationId: string, orgId: string) => void;
}

export interface Provider {
  id: string;
  name: string;
  key: string;
}
