import { useRouter } from "next/router";
import { useCallback } from "react";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "store";
import { saveAction } from "store/breadcrumb/breadcrumb.slice";

interface UseBreadcrumb {
  save: (checkpoint: string, url: string) => void;
  restore: (checkpoint: string, fallbackRoute: Record<any, any>) => void;
}

export default function useBreadcrumb(): UseBreadcrumb {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const checkpoints = useSelector<RootState, Record<string, string>>((state) => state.breadcrumb.checkpoints);

  const save = useCallback(
    (checkpoint: string, path: string) => {
      dispatch(saveAction({ checkpoint, path }));
    },
    [dispatch],
  );

  const restore = useCallback(
    (checkpoint: string, fallbackRoute: { pathname: string; query?: Record<string, string | number> }) => {
      if (checkpoints[checkpoint]) {
        router.push(checkpoints[checkpoint]);
      } else {
        router.push(fallbackRoute);
      }
    },
    [router.isReady, checkpoints],
  );

  return {
    save,
    restore,
  };
}
