import { FormControlLabel, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps } from "@material-ui/core";
import React, { ReactElement } from "react";

import CheckboxBlank from "@/icons/checkbox/CheckboxBlank";
import CheckboxChecked from "@/icons/checkbox/CheckboxChecked";

import styles from "./checkbox.module.css";

export interface CheckboxProps extends MuiCheckboxProps {
  label?: string;
  iconBlank?: ReactElement;
  iconChecked?: ReactElement;
}

const Checkbox = (props: CheckboxProps) => {
  const icon = props.iconBlank ?? <CheckboxBlank disabled={props.disabled} />;
  const checkedIcon = props.iconChecked ?? <CheckboxChecked disabled={props.disabled} />;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { iconBlank, iconChecked, ...filterProps } = props;

  if (props.label) {
    return (
      <FormControlLabel
        classes={{ root: styles.formControl, label: styles.label }}
        control={<MuiCheckbox icon={icon} checkedIcon={checkedIcon} {...filterProps} />}
        label={props.label}
        className={props.className}
      />
    );
  }
  return <MuiCheckbox icon={icon} checkedIcon={checkedIcon} {...filterProps} />;
};

export default Checkbox;
