export enum OffboardingPageVariants {
  ACCOUNT_ACTIVATED = "activated",
  REFERENCE_REQUESTED = "requested",
}

export enum AdminApprovalPageVariants {
  APPROVED = "approved",
  DECLINED = "declined",
}

export const verifierPageUrls = {
  signUpCreateAccount: () => ({
    pathname: "/sign-up/create-account",
  }),
  signUpAboutYou: (organisationId: string) => ({
    pathname: `/organisation/${organisationId}/about-you`,
  }),
  confirmEmail: () => ({
    pathname: "/sign-up/confirm-email",
  }),
  inboundReferencingApplicantDetails: (employerId: string) => ({
    pathname: "/inbound-referencing/applicant-details",
    query: {
      employerId,
    },
  }),
  inboundReferencingAboutYou: () => ({
    pathname: "/inbound-referencing/about-you",
  }),
  inboundReferencingConfirmRequest: () => ({
    pathname: "/inbound-referencing/confirm-request",
  }),
  inboundReferencingConfirmEmail: () => ({
    pathname: "/inbound-referencing/confirm-email",
  }),
  inboundReferencingOffboarding: (variant: OffboardingPageVariants) => ({
    pathname: "/inbound-referencing/offboarding",
    query: {
      variant,
    },
  }),
  inboundReferencingAdminApproval: (variant: AdminApprovalPageVariants) => ({
    pathname: "/inbound-referencing/admin-approval",
    query: {
      variant,
    },
  }),
  inboundReferencingApprovalNeeded: () => ({
    pathname: "/inbound-referencing/approval-needed",
  }),
  respondMyselfReferenceDetails: (employerId: string) => ({
    pathname: "/respond/self/reference-details",
    query: {
      employerId,
    },
  }),
  respondMyselfAboutYou: () => ({
    pathname: "/respond/self/about-you",
  }),
  respondMyselfVerifyData: () => ({
    pathname: "/respond/self/verify-data",
  }),
  respondMyselfGeneratingReference: (verificationId: string) => ({
    pathname: "/respond/self/generating-reference",
    query: {
      verificationId,
    },
  }),
  respondMyselfVerificationCompleted: () => ({
    pathname: "/respond/self/verification-completed",
  }),
  respondMyselfVerificationFailed: () => ({
    pathname: "/respond/self/verification-failed",
  }),
  signIn: () => ({
    pathname: "/sign-in",
  }),
  requests: (organisationId: string) => ({
    pathname: `/organisation/${organisationId}/requests`,
    query: {
      page: 1,
      limit: 10,
    },
  }),
  manualInvite: (organisationId: string) => ({
    pathname: `/organisation/${organisationId}/requests/manual-invite`,
  }),
  requestDetail: (organisationId: string, requestId: string) => ({
    pathname: `/organisation/${organisationId}/requests/${requestId}`,
  }),
  verificationDetails: (organisationId: string, verificationId: string) => ({
    pathname: `/organisation/${organisationId}/verifications/${verificationId}`,
  }),
  spendingTransactions: (organisationId: string, requestId: string, activityId: string) => ({
    pathname: `/organisation/${organisationId}/requests/${requestId}/activity/${activityId}/spending-transactions`,
    query: {
      page: 1,
      limit: 20,
    },
  }),
  incomeTransactions: (
    organisationId: string,
    requestId: string,
    activityId: string,
    providerId?: string,
    description?: string,
  ): IncomeTransactionUrlObject => ({
    pathname: `/organisation/${organisationId}/requests/${requestId}/activity/${activityId}/income-transactions`,
    query: {
      page: 1,
      limit: 20,
      ...(providerId && { accountProviderId: providerId }),
      ...(description && { merchant: encodeURIComponent(description) }),
    },
  }),
  settings: (organisationId: string) => ({
    pathname: `/organisation/${organisationId}/user-list`,
    query: {
      page: 1,
      limit: 10,
    },
  }),
  channels: (organisationId: string) => ({
    pathname: `/organisation/${organisationId}/channels`,
    query: {
      page: 1,
      limit: 10,
    },
  }),
};

export const konfirWebsiteBaseUrl = "https://konfir.com";

export interface CandidatePageUrlsType {
  pathname: string;
  query?: { isSandbox?: string; attempt?: string } | undefined;
}

export interface IncomeTransactionUrlObject {
  pathname: string;
  query: {
    page: number;
    limit: number;
    providerId?: string;
    merchant?: string;
  };
}
